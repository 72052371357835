<template>
  <div class="role">
    <div class="content">
      <el-row>
        <el-col>
          <el-button type="primary" @click="addOrUpdateHandle('add')">新建角色</el-button>
        </el-col>
      </el-row>
      <div class="tableBox">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          max-height="650"
          border
          style="width: 100%">
          <el-table-column
              prop="id"
              label="角色ID">
          </el-table-column>
          <el-table-column
              prop="name"
              label="角色">
          </el-table-column>
          <el-table-column
              prop="createAt"
              label="创建时间">
          </el-table-column>
          <el-table-column
                    label="操作"
                    width="200">
                    <template slot-scope="scope">
                        <el-button
                        @click.native.prevent="handleEdit(scope.$index, scope.row)"
                        type="primary">
                        编辑
                        </el-button>
                        <el-button
                        @click.native.prevent="handleDetail(scope.$index, scope.row)"
                        type="primary">
                        查看权限
                        </el-button>
                        <!-- <el-button
                        @click.native.prevent="handleDelete(scope.$index, scope.row)"
                        type="danger">
                        删除
                        </el-button> -->
                    </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="delVisible"
      :before-close="beforeClose"
      width="30%">
      <div>
        <div v-if="menuListLoading" style="text-align: center;">加载中...</div>
        <div v-else>
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="80px">
            <el-form-item label="角色名" prop="name">
              <el-input placeholder="请输入角色名" v-model.trim="form.name" :clearable="true"></el-input>
            </el-form-item>
          </el-form>
          <el-tree
            ref="tree"
            :data="menuData"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            :default-checked-keys="defaultCheckedKeys">
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="beforeClose()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
       title="编辑"
      :visible.sync="updateVisible"
      :before-close="updateBeforeClose"
      width="30%">
      <div>
          <el-form
            ref="form2"
            :model="form2"
            :rules="rules2"
            label-width="80px">
            <el-form-item label="角色名" prop="form2Name">
              <el-input placeholder="请输入角色名" v-model.trim="form2.form2Name" :clearable="true"></el-input>
            </el-form-item>
          </el-form>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateBeforeClose()">取 消</el-button>
        <el-button type="primary" @click="updateConfirm()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
       title="权限详情"
      :visible.sync="detailVisible"
      :before-close="detailBeforeClose"
      width="60%">
      <div class="tableBox">
            <el-table
            :data="detailTableData"
                v-loading="tableLoading"
                row-key="id"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                max-height="650"
                border
                ref="multipleTable"
                style="width: 100%">
              <el-table-column
                prop="name"
                label="菜单名">
              </el-table-column>
              <el-table-column
                prop="url"
                label="路由">
              </el-table-column>
              <el-table-column
                prop="createAt"
                label="创建时间">
              </el-table-column>
              
            </el-table>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '../../../api/menu';
import { roleList, addRole ,updateRole,detailList} from '../../../api/role';

export default {
  data() {
      return {
        tableData: [],
        detailTableData: [],
        tableLoading: false,
        dialogTitle: '',
        delVisible: false,
        updateVisible:false,
        detailVisible:false,
        menuData: [],
        menuListLoading: false,
        itemId:'',
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        defaultCheckedKeys: [],
        form: {
          name: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入角色名111', trigger: 'blur' },
          ],
        },
        form2: {
          form2Name: '',
        },
        rules2: {
          form2Name: [
            { required: true, message: '请输入角色名', trigger: 'blur' },
          ],
        },
      }
    },
    mounted() {
      /**
       * @author XU
       */
      this.roleList();
    },
    methods: {
      async roleList() {
        try {
          this.tableLoading = true;
          let data = await roleList();
          this.tableData = data;
        } catch (error) {
          console.log("error:", error)
        }
        this.tableLoading = false;
      },
      async addRole() {
        try {
          const checkedKeys = this.$refs.tree.getCheckedKeys();
          const checkedNodes = this.$refs.tree.getCheckedNodes();
          checkedNodes.forEach(item => {
            if (item.parentId != 0 && !checkedKeys.includes(item.parentId)) {
              checkedKeys.push(item.parentId);
            }
          })
          const params = {
            name: this.form.name,
            permissionsId: checkedKeys.join()
          };
          await addRole(params);
          this.roleList();
        } catch (error) {
          console.log("error:", error)
        }
      },
      async getList() {
        try {
          this.menuListLoading = true;
          let data = await getList();
          this.menuData = data;
        } catch (error) {
          console.log("error:", error)
        }
        this.menuListLoading = false;
      },
      addOrUpdateHandle(type) {
        this.delVisible = true;
        if (type === 'add') {
          this.dialogTitle = '新增角色';
          this.getList();
        }
      },
      
      beforeClose() {
        this.delVisible = false;
        this.$refs['form'].resetFields();
      },
      confirm() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.addRole();
            this.beforeClose();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

     // 编辑按钮
     handleEdit(index,rows){
        this.updateVisible = true;
        this.itemId = rows.id;
        this.form2.form2Name = rows.name;
      },
      updateBeforeClose() {
        this.updateVisible = false;
        this.$refs['form2'].resetFields();
      },
      //编辑
      updateConfirm(){
        this.$refs['form2'].validate((valid) => {
          if (valid) {
            this.updateRole();
            this.updateBeforeClose();
          } else {
            console.log('error submit!!');
            return false;
          }

        });
      },
      async updateRole() {
        try {
          const params = {
            name: this.form2.form2Name,
            id:  this.itemId
          };
          await updateRole(params);
          this.roleList();
          this.$message({
                showClose: true,
                type: "success",
                message: "操作成功!"
          });
        } catch (error) {
          console.log("error:", error)
        }
      },
      detailBeforeClose() {
        this.detailVisible = false;
      },

      handleDetail(index, rows){
        this.detailVisible = true;
        this.itemId = rows.id;
        this.rolePermissionsList();
      },
      async rolePermissionsList(){
        try {
          let params = {
            roleId : this.itemId
          }
          let data = await detailList(params);
          this.detailTableData = data;
        } catch (error) {
          console.log("error",error)
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.role {
    .content{
        .tableBox {
            padding-top: 20px;
        }
    }
}
</style>